interface String {
	parseBytecodes();
	toCamel();
	toDash();
}

String.prototype.parseBytecodes = function(){
	return this
		.replace(/\[(br|table|tr|td|th|b)\]/gim, "<$1>")
		.replace(/\[\/(table|tr|td|th|b)\]/gim, "</$1>");
};

String.prototype.toCamel = function(){
	return this.replace(/[-_]([a-z])/ig, function(g){
		return g[1].toUpperCase();
	});
};

String.prototype.toDash = function(){
	return this.replace(/([a-z])([A-Z])/g, function(g){
		return g[0].toLowerCase()+"_"+g[1].toLowerCase();
	});
};