interface Date {
	addMinutes(minutes: number): Date;
	addHours(hours: number): Date;
	addDays(days: number): Date;
	addWeeks(weeks: number): Date;
	addMonths(months: number): Date;
	getDayStart(): Date;
	getDayEnd(): Date;
	getWeekStart(): Date;
	getWeekEnd(): Date;
	getMonthStart(): Date;
	getMonthEnd(): Date;
	getMonthLength(): number;
	clone(): Date;
	parseDate(mask: string): string;
	inRange(min: Date, max: Date): boolean;
	isToday(): boolean;
	isLess(date: Date): boolean;
	isGreater(date: Date): boolean;
	isLessEqual(date: Date): boolean;
	isGreaterEqual(date: Date): boolean;
	isEqual(data: Date): boolean;
	isSameDay(data: Date): boolean;
	getTimeFromLesson(lesson: any, useEnd?: boolean): Date;
	differenceInDays(date: Date);
	differenceInMinutes(date: Date);
	differenceInHours(date: Date);
	fromDateAndHour(date: string, hours: string);
};

function handleZero(value: number){
	return (value < 10 ? "0"+value : value).toString();
};

Date.prototype.clone = function(): Date {
	return new Date(this.getTime());
};

Date.prototype.isEqual = function(date: Date): boolean {
	return date && date.getTime() === this.getTime();
};

Date.prototype.isSameDay = function(day: Date): boolean {
	if (!(day instanceof Date)) return false;
	return (
		(this.getDate() === day.getDate()) &&
		(this.getMonth() === day.getMonth()) &&
		(this.getFullYear() === day.getFullYear())
	);
};

Date.prototype.addMinutes = function(minutes: number): Date {
	this.setTime(this.getTime() + (minutes * 60 * 1000));
	return this;
};

Date.prototype.addHours = function(hours: number): Date {
	this.setTime(this.getTime() + (hours * 60 * 60 * 1000));
	return this;
};

Date.prototype.addDays = function(days: number): Date {
	var date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
};

Date.prototype.addWeeks = function(weeks: number): Date {
	this.setTime(this.getTime() + (weeks * 7 * 24 * 60 * 60 * 1000));
	return this;
};

Date.prototype.addMonths = function(months: number): Date {
	// console.log(this.getMonth());
	this.setMonth(this.getMonth()+months);
	return this;
};

Date.prototype.getDayStart = function(): Date {
	this.setHours(0,0,0,0);
	return this.clone();
};

Date.prototype.getDayEnd = function(): Date {
	this.setHours(23,59,59,0);
	return this.clone();
};

Date.prototype.getWeekStart = function(): Date {
	let diff = this.getDate() - this.getDay() + (this.getDay() === 0 ? -6 : 1);
	this.setDate(diff);
	return this;
};

Date.prototype.getWeekEnd = function(): Date {
	let diff = this.getDate() - this.getDay() + (this.getDay() === 0 ? -6 : 1);
	this.setDate(diff+6);
	return this;
};

Date.prototype.getMonthStart = function(): Date {
	let diff = new Date(this.getFullYear(), this.getMonth(), 1);
	diff.setHours(0, 0, 0, 0);
	return diff;
};

Date.prototype.getMonthEnd = function(): Date {
	let start: Date = this.clone().getMonthStart();
	start.setHours(0, 0, 0, 0);
	return start.addMonths(1).addDays(-1);
};

Date.prototype.parseDate = function(mask: string): string {
	let DD = this.getDate(),
			MM = this.getMonth(),
			YYYY = this.getFullYear(),
			h = this.getHours(),
			m = this.getMinutes();
	return mask
		.replace(/dd/gim, handleZero(DD)).replace(/mm/gim, handleZero(MM+1)).replace(/yyyy/gim, YYYY)
		.replace(/h/gim, handleZero(h)).replace(/m/gim, handleZero(m));
};

Date.prototype.getMonthLength= function(): number {
	let date = new Date(this.getFullYear(), this.getMonth()+1, 0);
	return date.getDate();
};

Date.prototype.inRange = function(min: Date, max: Date): boolean {
		return this.getTime() >= min.getTime() && this.getTime() <= max.getTime();
};

Date.prototype.isToday = function(): boolean {
	let day: Date = new Date();
	return (
		(this.getDate() === day.getDate()) &&
		(this.getMonth() === day.getMonth()) &&
		(this.getFullYear() === day.getFullYear())
	);
};

Date.prototype.isLess = function(date: Date): boolean {
	return this.getTime() < date.getTime();
};

Date.prototype.isGreater = function(date: Date): boolean {
	return this.getTime() > date.getTime();
};

Date.prototype.isLessEqual = function(date: Date): boolean {
	return this.getTime() <= date.getTime();
};

Date.prototype.isGreaterEqual = function(date: Date): boolean {
	return this.getTime() >= date.getTime();
};


Date.prototype.getTimeFromLesson = function(lesson: any, useEnd?: boolean): Date {
	let z = (v)=> { return v < 10 ? "0"+v : v };
	let point = useEnd ? lesson.to_hour : lesson.from_hour,
			date = lesson.date,
			hour = Math.floor(point.toString() / 100),
			minute = point.toString().substr(-2);
	return new Date(`${date}T${z(hour)}:${minute}:00`);
};

Date.prototype.differenceInDays = function(date2: Date){
	const date1 = this;
	const diffTime = Math.abs(date2.getTime() - date1.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	return diffDays;
};

Date.prototype.differenceInHours = function(date2: Date){
	const date1 = this;
	const diffTime = Math.abs(date2.getTime() - date1.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60));
	return diffDays;
};

Date.prototype.differenceInMinutes = function(date2: Date){
	const date1 = this;
	const diffTime = Math.abs(date2.getTime() - date1.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60));
	return diffDays;
};

Date.prototype.fromDateAndHour = function(date: string, hours: string): Date {
	let dateArray: number[] = date.split(".").map(v => parseInt(v));
	let timeArray: number[] = hours.split(":").map(v => parseInt(v));
	// 1995-12-17T03:24:00Z
	return new Date(dateArray[2], dateArray[1]-1, dateArray[0], timeArray[0], timeArray[1], 0);
};

