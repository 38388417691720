interface ElementDistance {
	top: number, bottom: number,
	left: number, right: number
};

interface Math {
	clamp(value: number, min: number, max: number): number;
	degreesToRadians(degrees: number): number;
	radiansToDegrees(radians: number): number;
	randomInt(min: number, max: number): number;
	randomHash(length?: number): string;
	randomFloat(min: number, max: number): number;
	size(value: number): string;
	lerp(a: number, b: number, t: number): number;
	getElementDistances(bbox: ClientRect): ElementDistance;
}

Math.clamp = function(value: number, min: number, max: number): number {
	return value < 0 ? 0 : value > max ? max : value;
};

Math.degreesToRadians = function(degrees: number): number {
	return degrees * (Math.PI / 180);
};

Math.radiansToDegrees = function(radians: number): number {
	return radians * (180 / Math.PI);
};

Math.randomInt = function(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min)) + min;
};

Math.randomFloat = function(min: number, max: number): number {
	return Math.random() * (max - min) + min;
};

Math.randomHash = function(length?: number): string {
	if (!length) {
		length = 6;
	}
	let letters = "abcdefghijklmnopqrstuvwxyz";
	let result: string = "";
	for (let i = 0; i < length; i++){
		result += letters[Math.randomInt(0, letters.length-1)];
	}
	return result;
}

Math.size = function(value: number): string {
	let i: number = Math.floor(Math.log(value) / Math.log(1024));
	let num: any = (value / Math.pow(1024, i)).toFixed(2);
	return num + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

Math.lerp = function(a: number, b: number, t: number): number {
	return a + (b - a) * t;
};

Math.getElementDistances = function(bbox: ClientRect): ElementDistance {
	return {
		top: bbox.top,
		bottom: window.innerHeight - bbox.bottom,
		left: bbox.left,
		right: window.innerWidth - bbox.right
	};
};
